import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

// Components
import DoughSelector from './DoughSelector';
import SizeSelector from '../SizeSelector';
import BorderToggle from './BorderToggle';

// Styles
import styles from './PizzaSelector.styl';

const cx = classNames.bind(styles);

export default function PizzaSelector(props) {
  const {
    selectStuffedCrust,
    className,
    doughTypes,
    currentDough,
    changeDough,
    sizes,
    currentSize,
    changeSize,
    stuffedCrustIsAvailable,
    stuffedCrustsAvailable,
    activeStuffedCrust,
    toggleStuffedCrust,
    leftSidePizzaId,
    rightSidePizzaId,
    isBottom,
    initialStuffedCrust,
    specialStylesMode
  } = props;

  useEffect(() => {
    if (initialStuffedCrust) {
      toggleStuffedCrust(initialStuffedCrust);
    } else {
      const newStuffedCrust = stuffedCrustsAvailable[0] || 'none';
      toggleStuffedCrust(newStuffedCrust);
    }
  }, [currentSize, currentDough, leftSidePizzaId, rightSidePizzaId, initialStuffedCrust]);

  return (
    <div className={cx('PizzaSelector', className)} data-test-id='pizza_selector'>
      {doughTypes && (
        <DoughSelector
          className={cx('PizzaSelector__dough')}
          types={doughTypes}
          currentDough={Number(currentDough)}
          onChange={changeDough}
          specialStylesMode={specialStylesMode}
        />
      )}
      <div className={cx('PizzaSelector__variations')} data-test-id='pizza_selector_size'>
        {sizes.length > 0 && (
          <SizeSelector sizes={sizes} currentSize={currentSize} onChange={changeSize}
                        specialStylesMode={specialStylesMode}
          />
        )}
        {stuffedCrustIsAvailable && (
          <BorderToggle
            className={cx('PizzaSelector__border')}
            selectStuffedCrust={selectStuffedCrust}
            stuffedCrustsAvailable={stuffedCrustsAvailable}
            activeStuffedCrust={activeStuffedCrust}
            isBottom={isBottom}
            specialStylesMode={specialStylesMode}
          />
        )}
      </div>
    </div>
  );
}

PizzaSelector.defaultProps = {
  className: '',
  leftSidePizzaId: 0,
  rightSidePizzaId: 0,
  stuffedCrustsAvailable: [],
  isBottom: false,
  initialStuffedCrust: null,
};

PizzaSelector.propTypes = {
  className: PropTypes.string,
  sizes: PropTypes.array.isRequired,
  doughTypes: PropTypes.array.isRequired,
  changeSize: PropTypes.func.isRequired,
  changeDough: PropTypes.func.isRequired,
  selectStuffedCrust: PropTypes.func.isRequired,
  toggleStuffedCrust: PropTypes.func.isRequired,
  stuffedCrustsAvailable: PropTypes.array,
  activeStuffedCrust: PropTypes.string.isRequired,
  leftSidePizzaId: PropTypes.number,
  rightSidePizzaId: PropTypes.number,
  stuffedCrustIsAvailable: PropTypes.bool,
  currentSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  currentDough: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isBottom: PropTypes.bool,
  initialStuffedCrust: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
