import _uniq from 'lodash/uniq';
import _uniqBy from 'lodash/uniqBy';

export const getDoughTypes = variations => {
  const newVariations = variations.map(variant => variant.kind ? { id: variant.kind.id, label: variant.dough } : null)
  return _uniqBy(newVariations, 'label');
};

export const getVariationsByDoughPizza = (variations, doughId) => {
  const variationsWithKindId = variations.filter(variant => variant.kind !== null);
  return variationsWithKindId.filter(variant => variant.kind.id === Number(doughId));
};

export const getVariationsByDough = (variations, dough) => {
  return variations.filter(variant => variant.dough === dough);
};

export const getSizes = variations => {
  const uniqSizes = _uniqBy(
    variations.map(variant => variant.size),
    'value',
  );

  return uniqSizes.filter(Boolean);
};

export const getVariation = (variations, sizeValue, stuffedCrust = 'none') => {
  const result = variations.find(variant => variant.size?.value === Number(sizeValue) &&
    (!variant.stuffed_crust || variant.stuffed_crust === stuffedCrust),
  );
  return result || variations[0] || {};
};

// доступно ли чтото кроме стандартного
export const getStuffedCrustIsAvailable = (variations, size) => {
  return Boolean(
    variations.find(
      variant => variant.size?.value === Number(size) && variant.stuffed_crust !== 'none',
    ),
  );
};

// доступно ли чтото при этом стандартное остуствует
export const getIsOnlyStuffedCrustIsAvailable = (variations, size) => {
  const stuffedCrustIsAvailable = getStuffedCrustIsAvailable(variations, size);

  return (
    stuffedCrustIsAvailable &&
    !variations.find(
      variant => variant.size?.value === Number(size) && variant.stuffed_crust === 'none',
    )
  );
};

export const getStuffedCrusts = (variations, size) => {
  return _uniq(
    variations
      .filter(variant => variant.size?.value === Number(size))
      .map(variant => variant.stuffed_crust),
  );
};

export const getPersonCount = variation => {
  const characteristics = variation?.characteristics || [];
  const characteristic = characteristics.find(char => char.code === 'per_person') ;

  return characteristic?.value || '';
};

export const getRemovedIngredientsCount = variation => {
  const includeIngredients = variation?.include_ingredients || [];

  return includeIngredients
    .filter(item => item.removed)
    .reduce((acc, item) => acc + Number(item.count), 0);
};
