import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import _isEmpty from 'lodash/isEmpty';

// Styles
import styles from './SizeSelector.styl';
import {useSelector} from "react-redux";
import {isThemeHalloween, isThemeNewYear} from "../../state/modules/city/selectors";

const cx = classNames.bind(styles);

export default function SizeSelector(props) {
  const { className, currentSize, onChange, sizes, specialStylesMode } = props;
  const isHalloweenMode = useSelector(isThemeHalloween);
  const isNewYearMode = useSelector(isThemeNewYear);

  const foundedIndex = sizes
    .sort((a, b) => a.value - b.value)
    .findIndex(size => size.value === currentSize);

  const overlayStyle = {
    width: `${100 / sizes.length}%`,
    transform: `translateX(${foundedIndex === -1 ? 0 : foundedIndex * 100}%)`,
  };

  const handleChange = size => {
    return size.value === currentSize ? false : onChange(size.value);
  };

  // если не пришел текущий размер, то устанавливаем первый из доступных
  useEffect(() => {
    if (!currentSize && sizes[0]) {
      onChange(sizes[0].value);
    }
  }, []);

  // Если выбранного размера нет в другом виде теста,
  // то активируем первый размер из списка
  useEffect(() => {
    if (!_isEmpty(sizes)) {
      const foundedSize = sizes.find(size => size.value === currentSize);

      if (!foundedSize) {
        onChange(sizes[0]?.value);
      }
    }
  }, [sizes]);

  if (sizes.length === 0 || !sizes) {
    return null;
  }

  return (
    <div className={cx('SizeSelector', className, {
      'SizeSelector__halloween': isHalloweenMode && specialStylesMode,
      'SizeSelector__new-year': isNewYearMode && specialStylesMode,
    })} data-test-id='size_selector'>
      <div className={cx('SizeSelector__overlay')} style={overlayStyle}/>
      {sizes
        .sort((a, b) => a.value - b.value)
        .map(size => (
          <div
            className={cx('SizeSelector__size', {
              SizeSelector__size_selected: currentSize === size.value,
            })}
            key={size.id}
            onClick={() => handleChange(size)}
            onKeyDown={e => e.key === 'Enter' && handleChange(size)}
            role="button"
            tabIndex={0}
          >
            {currentSize === size.value ? `${size.value} ${size.unit}` : size.value}
          </div>
        ))}
    </div>
  );
}

SizeSelector.defaultProps = {
  className: '',
  sizes: [],
};

SizeSelector.propTypes = {
  className: PropTypes.string,
  sizes: PropTypes.array,
  onChange: PropTypes.func.isRequired,
  currentSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};
